
import styles from '../../../assets/css/styling.module.css'
import styles2 from '../../../assets/css/rstyling.module.css'
import styles3 from '../../../assets/css/newStyling.module.css'

export const Respondent_Nav_Content = [
    {
        icon: `${styles.sidebar_menu_icon} ${styles3.dashboard_ico}`,
        label: "Dashboard",
        to: "/respondent",
    
    },
    {
        icon: `${styles.sidebar_menu_icon} ${styles3.project_ico}`,
        label: "Project",
        to: "/respondent/projects"
    },
    {
        icon: `${styles.sidebar_menu_icon} ${styles3.community_ico}`,
        label: "Community",
        to: "/respondent/group-forums"
    },
    {
        icon: `${styles.sidebar_menu_icon} ${styles3.rewards_ico}`,
        label: "Rewards",
        to: "/respondent/rewards"
    },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles3.notifications_ico}`,
    //     label: "Notification",
    //     to: "/respondent/notifications"
    // },
    {
        icon: `${styles.sidebar_menu_icon}  ${styles3.settings_ico}`,
        label: "Settings",
        to: "/respondent/profile"
    },
    // {
    //     icon: `${styles.sidebar_menu_icon_new}  ${styles3.help_ico}`,
    //     label: "Help",
    //     to: "/respondent/profile"
    // },
    // {
    //     icon: `${styles.sidebar_menu_icon_new_last}  ${styles3.logout_ico}`,
    //     label: "Log Out",
    //     to: "/respondent/profile"
    // },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles.bottom_icon} ${styles.question_ico}`,
    //     label: "Help",
    //     to: "/respondent/profile",
        
    // },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles.logout_ico}`,
    //     label: "Log Out",
    //     to: "/respondent/profile"
    // },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles.profile_icon}`,
    //     label: "Profile",
    //     to: "/respondent/profile",
    // },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles.referral_icon}`,
    //     label: "Referral",
    //     to: "/respondent/referral",
    // },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles.research_histroy_icon}`,
    //     label: "Research History",
    //     to: "/respondent/research-history",
    // },

];



export const Admin_Nav_Content = [
    {
        icon: `${styles.sidebar_menu_icon} ${styles.dashboard_ico}`,
        label: "Dashboard",
        to: "/admin",
    },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles.referral_icon}`,
    //     label: "Referrals",
    //     to: "/admin/referral",
    // },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.research_project_icon}`,
        label: "Research Projects",
        to: "/admin/project",
    },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.quicksurvey_icon}`,
        label: "Quick Surveys",
        to: "/admin/minisurvey",
    },
    {
        icon: `${styles.sidebar_menu_icon} ${styles.forum_icon}`,
        label: "General Forums",
        to: "/admin/general-forum",
    },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.user_management_icon}`,
        label: "Users Management",
        to: "/admin/user",
    },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.broadcast_message_icon}`,
        label: "Broadcast Messages",
        to: "/admin/broadcast-message",
    },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.templates_icon}`,
    //     label: "Templates",
    //     to: "/admin/templates/email",
    //     content: [
    //         {
    //             label: "Emails",
    //             to: "/admin/templates/email",
    //         },
    //         // {
    //         //     label: "SMS",
    //         //     to: "/admin/templates/sms",
    //         // },
    //         // {
    //         //     label: "Campaigns",
    //         //     to: "/admin/templates/campaign",
    //         // },
    //     ],
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.interview_icon}`,
    //     label: "Interviews",
    //     to: "/admin/interview",
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.chat_icon}`,
    //     label: "Chats",
    //     to: "/admin/chat",
    // },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.setting_icon}`,
        label: "Settings",
        to: "/admin/settings",
    },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles3.admin_rewards_ico}`,
        label: "Rewards",
        to: "/admin/rewards",
    },

];

export const Researcher_Nav_Content = [
    {
        icon: `${styles.sidebar_menu_icon} ${styles.dashboard_ico}`,
        label: "Dashboard",
        to: "/researcher",
    },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles.referral_icon}`,
    //     label: "Referrals",
    //     to: "/researcher/referral",
    // },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.research_project_icon}`,
        label: "Research Projects",
        to: "/researcher/project",
    },
    {
        icon: `${styles2.sidebar_menu_icon} ${styles2.quicksurvey_icon}`,
        label: "Quick Surveys",
        to: "/researcher/minisurvey",
    },
    // {
    //     icon: `${styles.sidebar_menu_icon} ${styles.forum_icon}`,
    //     label: "General Forums",
    //     to: "/researcher/general-forum",
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.user_management_icon}`,
    //     label: "Users Management",
    //     to: "/researcher/user",
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.broadcast_message_icon}`,
    //     label: "Broadcast Messages",
    //     to: "/researcher/broadcast-message",
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.templates_icon}`,
    //     label: "Templates",
    //     to: "/researcher/templates/email",
    //     content: [
    //         {
    //             label: "Emails",
    //             to: "/researcher/templates/email",
    //         },
    //         {
    //             label: "SMS",
    //             to: "/researcher/templates/sms",
    //         },
    //         {
    //             label: "Campaigns",
    //             to: "/researcher/templates/campaign",
    //         },
    //     ],
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.chat_icon}`,
    //     label: "Chats",
    //     to: "/researcher/chat",
    // },
    // {
    //     icon: `${styles2.sidebar_menu_icon} ${styles2.setting_icon}`,
    //     label: "Settings",
    //     to: "/researcher/settings",
    // },

];