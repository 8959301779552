import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { TransitionGroup } from 'react-transition-group';

import HeaderLogo from "../AppLogo";
import Logo from '../../assets/images/CC-Logo.png'
import SearchBox from "./Components/SearchBox";
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";
import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import axios from 'axios'
import HeaderDots from "./Components/HeaderDots";
import ResizeDetector from "react-resize-detector";
import Notifications from "../../pages/Notifications/Notifications";
import ProfileCard from "../../pages/Profile/ProfileCard";
import profile from '../../assets/images/profile_image.jpg'
import SecureLS from 'secure-ls';
import ProfileNotification from "../../pages/Respondent/Dashboard/ProfileNotification";
import { BACK_SERVER_URL } from "../../config/server";
class Header extends React.Component {


	constructor(props) {

		super(props);
		this.state = {
			closedSmallerSidebar: false,
			// notifications: ['Checkout our latest post', 'Checkout our latest post', 'Checkout our latest survey', '20 responses unreviewed'],
			// userProfile: {
			// 	userName: 'Jawaria',
			// 	role: 2,
			// 	messages: '04',
			// 	lastVisit: '56m',
			// 	joinedStudy: '4 Days',
			// 	studyVisits: '15',
			// 	profileImage: profile
			// }
		};
		this.ls = new SecureLS()
		this.checkUrl = this.checkUrl.bind(this)
		this.state = {
			userData: null,
		};
		this.state = {
			currentDateTime: new Date(),
		};
		this.state = {
			profileCompletion: 0,
			notificationType: ''
		}
	}

	checkUrl() {
		const pathname = window.location.pathname
		const path = pathname.slice(0, pathname.lastIndexOf('/'))

		if (path.slice(0, path.lastIndexOf('/')) === '/researcher/project' || path.slice(0, path.lastIndexOf('/')) === '/admin/project') {
			return true
		} else return false





	}
	componentDidMount() {
		const retrievedUserData = this.ls.get('accessUser');
		const userName = retrievedUserData.user.firstName
		const userId = retrievedUserData.user._id

		this.setState({ userData: userName })
		this.interval = setInterval(() => {
			this.setState({ currentDateTime: new Date() });
		}, 1000);

		// const url = `${BACK_SERVER_URL}api/users/profileCompletion/${userId}`
		// const request = axios.get(url)
		// 	.then(response => {

		// 		this.setState({ profileCompletion: response.data.content })
		// 		this.setState({ notificationType: response.data.message })
		// 	}).catch(err => { console.log(err) })

	}
	componentWillUnmount() {
		// Clear the interval when the component is unmounted to avoid memory leaks
		clearInterval(this.interval);
	}
	formatDate() {
		const date = new Date();

		const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		const months = [
			'January', 'February', 'March', 'April', 'May', 'June', 'July',
			'August', 'September', 'October', 'November', 'December'
		];

		const currentDay = daysOfWeek[date.getDay()];
		const currentDate = date.getDate();
		const currentMonth = months[date.getMonth()];
		const currentYear = date.getFullYear();

		return `${currentDay}, ${currentDate} ${currentMonth} ${currentYear}`;
	}




	render() {
		const { userData } = this.state;
		let {
			headerBackgroundColor,
			enableMobileMenuSmall,
			enableHeaderShadow
		} = this.props;
		const formattedDate = this.formatDate();

		return (
			<ResizeDetector
				handleWidth
				render={({ width }) => (
					<Fragment>
						<TransitionGroup component="div"
							className={cx("app-header", "border-bottom", headerBackgroundColor, {
								"header-shadow": enableHeaderShadow,
							})}
							// style={{marginLeft: "280px" }}
							transitionname="HeaderAnimation" transitionAppear={true} transitionAppearTimeout={1500}
							transitionEnter={false} transitionLeave={false}>

							<HeaderLogo />

							{/* <div className={cx("app-header__content", {
								"header-mobile-open": enableMobileMenuSmall,
							})}/>  */}
							{/* <div style={{display:'flex', alignItems:'center', alignContent:'center'}}> */}
							<div className='app-header__content pt-2'>


								<div className='app-header-left m-0'>
									{window.innerWidth > 768 ?
										<>
											<h4 style={{
												color: '#3e435d', fontSize: '27px', fontWeight: '500', overflow: 'hidden',
												whiteSpace: 'nowrap', textOverflow: 'ellipsis'
											}} >Welcome, {userData}</h4>

											<p style={{ color: '#ada7a7', fontWeight: '600' }}>{formattedDate}</p>
										</> :
										<img className='m-auto' src={Logo}></img>
									}
								</div>
								{this.state.profileCompletion && this.state.profileCompletion < 100 && this.state.notificationType === 'In-progress projects found' && window.innerWidth > 768 ?


									<div className='app-header-middle pl-2'>
										<ProfileNotification />
									</div> : ''
								}
								{<div className="app-header-right ">
									{/* <HeaderDots /> */}
									{/* Notifications */}
									<Notifications />
									{/* Profile */}
									<div className=' pt-1 d-none d-sm-block'>
										<ProfileCard />
									</div>
									{/* <UserBox /> */}
									{/* <HeaderRightDrawer /> */}
								</div>}

							</div>
						</TransitionGroup>
					</Fragment>
				)}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
