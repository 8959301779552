import React from 'react';
import { IoMenuOutline } from "react-icons/io5";
const FAQ = () => {
  return (
    <div >
      <h2 style={{color:'#000', fontWeight:'500'}}>Frequently Asked Questions (FAQs)</h2>

      <div className="faq-item mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: What is Consumer Connect?</h5>
        <p>Consumer Connect serves as a platform where individuals can contribute their opinions through online polls covering diverse topics such as politics, public affairs, products, brands, and general interest subjects. These surveys are global in scope and play a crucial role in analyzing consumer behavior and generating valuable insights for organizations across various sectors.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: For what purposes is the data collected by Consumer Connect used?</h5>
        <p>The data gathered from Consumer Connect surveys is employed for the analysis of consumer behavior, providing organizations with insights into current affairs, consumer products, and brands. This information aids decision-making processes across different industries.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: Who pays for these polls?</h5>
        <p>Consumer Connect conducts surveys on behalf of a diverse clientele seeking to understand public perspectives. Respondents can participate in polls with the assurance of privacy, and their contributions are instrumental in shaping opinions on a range of topics.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: Will the information I provide to Consumer Connect be confidential?</h5>
        <p>Yes, individual responses are treated with strict confidentiality, in accordance with our <a href="https://consumerconnect.net/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Personal identities are never disclosed, ensuring the privacy and anonymity of respondents.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: What is a project in Consumer Connect?</h5>
        <p>A project in Consumer Connect is a combination of various activities, including surveys, group discussions, online interviews, and forums. These projects provide participants with a comprehensive and diverse engagement experience.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: Where can I see my responses for a certain survey?</h5>
        <p>Upon completing a survey, respondents are redirected to a page where they can review all their responses, providing transparency and allowing them to revisit their contributions.</p>
      </div>
      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: What benefits do I get by completing my profile after signup?</h5>
        <p>Completing the profile after signup enhances the user experience by enabling better project matching and notifications, ensuring a more personalized engagement.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: Do I have to buy or subscribe to anything?</h5>
        <p>No, participation in Consumer Connect surveys and polls does not require any purchase or subscription. The platform is solely focused on gathering valuable consumer feedback.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: How can I reset my password?</h5>
        <p>In the event of a forgotten password, users can easily reset it by selecting the "Forgot your Password" option on the Login page.</p>
      </div>

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: Where can I change my personal details?</h5>
        <p>Users can update their personal details, including demographics, employment information, and interests, by navigating to the <IoMenuOutline color='black' size={20}/> Sidebar  Settings section.</p>
      </div>
     

      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: How can I refer someone and earn points?</h5>
        <p>Participants can earn points by referring individuals to Consumer Connect. The referral link is automatically copied by clicking on the "Earn points by referring people" banner.</p>
      </div>
      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: How many points do I receive for participating in Consumer Connect activities?</h5>
        <p>Participants earn points for completing surveys and can also earn points by referring others to join the Consumer Connect community.</p>
      </div>
      <div className="faq-item  mt-4">
        <h5 style={{color:'#000', fontWeight:'400'}}>Q: How do I get paid upon completing the paid activities?</h5>
        <p>Upon successfully completing surveys and reaching a certain point threshold, participants can apply for gift cards in the rewards section.</p>
      </div>

      
    </div>
  );
};

export default FAQ;

